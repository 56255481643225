<template>
    <v-menu dense>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                :disabled="disabled"
                small
                icon
                v-bind="attrs"
                @click.stop="onClick(on)"
                :title="displayOptions[toggle].text">
                <v-icon :class="displayOptions[toggle].value != 'Agreements' ? 'success--text' : ''" small>{{ displayOptions[toggle].icon }}</v-icon>
            </v-btn>
        </template>

        <v-list>
            <v-list-item-group
                v-model="toggle"
                @change="update">
                <template v-for="(f, i) in displayOptions">
                    <v-list-item :key="i">
                        <template v-slot:default="{ active }">
                            <v-list-item-action>
                                <v-icon v-if="active" small>mdi-check</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>{{ f.text }}</v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                </template>
            </v-list-item-group>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    name: 'BT-Toggle-Guide-Icon',
    data: () => {
        return {
            toggle: 0
        }
    },    
    created() {
        if (this.value) {
            var ind = this.displayOptions.findIndex(x => x.value === this.value);
            if (ind >= 0) {
                this.toggle = ind;
            }
            else {
                this.toggle = this.displayOptions.findIndex(x => x.value === this.defaultValue);
            }
        }
    },
    props: {
        value: {
            Type: String,
            default: null
        },
        defaultValue: {
            type: String,
            default: 'Settings'
        },
        toggleOptions: {
            Type: Array,
            default: () => { return [
                { text: 'Global', value: 'Settings', icon: 'mdi-earth' }, 
                { text: 'Individual', value: 'Agreements', icon: 'mdi-account-circle-outline' }] }
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        displayOptions() {
            return this.toggleOptions;
        }
    },
    methods: {
        onClick(callback) {
            if (this.displayOptions.length == 2) {
                this.toggle = this.toggle == 0 ? 1 : 0;
                this.update();
            }
            else {
                callback();
            }
        },
        update() {
            this.$emit('input', this.displayOptions[this.toggle].value);
            this.$emit('change', this.displayOptions[this.toggle].value);
        }
    }
}
</script>